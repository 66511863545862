import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as chainsActions from '../actions/chainsActions';

class Chains extends Component {

    componentDidMount() {
        const { getChains } = this.props.chainsActions;
        getChains();
    }

    handleApproveAll = async (chainId) => {
        if (window.confirm("Are you sure you want to approve all restaurants for this chain?")) {
            const { approveAllRestaurants } = this.props.chainsActions;
            const response = await approveAllRestaurants(chainId);
            if (response.success) {
                alert('All restaurants approved successfully');
            } else {
                alert(`Failed to approve all restaurants: ${response.error}`);
            }
        }
    };

    handleDisapproveAll = async (chainId) => {
        if (window.confirm("Are you sure you want to disapprove all restaurants for this chain?")) {
            const { disapproveAllRestaurants } = this.props.chainsActions;
            const response = await disapproveAllRestaurants(chainId);
            if (response.success) {
                alert('All restaurants disapproved successfully');
            } else {
                alert(`Failed to disapprove all restaurants: ${response.error}`);
            }
        }
    };

    render() {
        const { chains } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Chain Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chains.map((chain) => (
                            <TableRow key={chain.id}>
                                <TableCell>{chain.name}</TableCell>
                                <TableCell>
                                    <Button onClick={() => this.handleApproveAll(chain.id)}>Approve All</Button>
                                    <Button onClick={() => this.handleDisapproveAll(chain.id)}>Disapprove All</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return { chains: state.chains.chains };
};

const mapDispatchToProps = (dispatch) => {
    return { chainsActions: bindActionCreators(chainsActions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chains);