import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as adminsActions from '../../actions/adminsActions';
import * as contactActions from '../../actions/contactActions';
import * as labellingActions from '../../actions/labellingActions';
import * as loginActions from '../../actions/loginActions';
import * as menusActions from '../../actions/menusActions';
import * as migrationActions from '../../actions/migrationActions';
import * as reviewsActions from '../../actions/reviewsActions';
import * as supportActions from '../../actions/supportActions';
import * as usersActions from '../../actions/usersActions';
import {IS_US, TEST_ENV} from '../../resources/Api';
import Button from '../utils/Button';
import Text from '../utils/Text';
import Loading from './Loading';

const GREEN = '#28a745';

class Navbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			initedUser: false,
			country: null,
		};
	}

	async initUser(superAdmin = false) {
		if (this.state.initedUser) return false;

		const {migrationActions, reviewsActions, contactActions, labellingActions, usersActions} =
			this.props;
		this.setState({initedUser: true});

		reviewsActions.getReviews();
		contactActions.getContactTickets();
		//labellingActions.getCompanies();
		//migrationActions.getVfServiceProviders();
		//migrationActions.getVfBusinesses();
	}

	doRehash() {
		const {loginActions} = this.props;

		loginActions.testHash();
	}

	componentDidMount() {
		if (this.props.login.loggedIn) {
			this.initUser(this.props.login.superAdmin);
		} else if (this.props.login.rehash) {
			this.doRehash();
		}
		const country = localStorage.getItem('country');
		this.setState({country});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.login.loggedIn) {
			this.initUser(this.props.login.superAdmin);
		}
	}

	onCountryClick(country) {
		this.setState({country});
		localStorage.setItem('country', country);
		window.location.reload(false);
	}

	render() {
		const {loggedIn, superAdmin} = this.props.login;
		const {pendingReviews} = this.props.reviews;
		const {support, contact, login, menus, users} = this.props;
		const unreadSupportTickets = support.tickets
			? support.tickets.filter((ticket) => !ticket.read).length
			: 0;
		const unreadContactTickets = contact.tickets
			? contact.tickets.filter((ticket) => !ticket.read).length
			: 0;

		const {country} = this.state;

		return (
			<div
				className={'navbar navbar-expand-lg navbar-light justify-content-between'}
				style={TEST_ENV ? {backgroundColor: '#f8d7da'} : null}
			>
				<div className="container">
					<div className={'center-flex'}>
						<NavLink to="/">
							<img className="logo" alt="logo" src={process.env.PUBLIC_URL + '/logo.png'} />
						</NavLink>
						<ul className="navbar-nav">
							<li>
								<NavLink end to={'/'} className="nav-link" activeclassname="active">
									<Text>sections.home</Text>
								</NavLink>
							</li>
							{loggedIn && (
								<li>
									<NavLink to={'/reviews'} className="nav-link" activeclassname="active">
										<Text>sections.reviews</Text>
										{pendingReviews.length > 0 && (
											<div className="badge badge-pill mx-1 badge-primary">
												{pendingReviews.length}
											</div>
										)}
									</NavLink>
								</li>
							)}

							{loggedIn && (
								<li>
									<NavLink to={'/restaurants'} className="nav-link" activeclassname="active">
										<Text>sections.restaurants</Text>
									</NavLink>
								</li>
							)}
							{loggedIn && (
                                <li>
                                    <NavLink to={'/chains'} className="nav-link" activeclassname="active">
                                        <Text>sections.chains</Text>
                                    </NavLink>
                                </li>
                            )}
							
							{loggedIn && !IS_US && (
								<li>
									<Button
										onClick={() => this.onCountryClick('IL')}
										className="nav-link"
										activeclassname="active"
									>
										<label
											style={
												country === 'IL'
													? {fontWeight: 'bold', backgroundColor: GREEN}
													: {fontWeight: 'bold', color: 'pink'}
											}
										>
											Israel
										</label>
									</Button>
								</li>
							)}

							{loggedIn && !IS_US && (
								<li>
									<Button
										onClick={() => this.onCountryClick('GB')}
										className="nav-link"
										activeclassname="active"
									>
										<label
											style={
												country === 'GB'
													? {fontWeight: 'bold', backgroundColor: GREEN}
													: {fontWeight: 'bold', color: 'pink'}
											}
										>
											United Kingdom
										</label>
									</Button>
								</li>
							)}

							{loggedIn && IS_US && (
								<li>
									<Button
										onClick={() => this.onCountryClick('US')}
										className="nav-link"
										activeclassname="active"
									>
										<label
											style={
												country === 'US'
													? {fontWeight: 'bold', backgroundColor: GREEN}
													: {fontWeight: 'bold', color: 'pink'}
											}
										>
											United States
										</label>
									</Button>
								</li>
							)}
						</ul>
					</div>
					<div className="navbar-text text-center">
						{loggedIn ? <Text>login.loggedIn</Text> : <Text>login.signedOut</Text>}
						<br />
						{loggedIn && (
							<NavLink to={'/logout'}>
								[<Text>login.logOut</Text>]
							</NavLink>
						)}
					</div>
				</div>
				{(login.loading || menus.loading || users.loading) && <Loading />}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.login,
		reviews: state.reviews,
		support: state.support,
		contact: state.contact,
		menus: state.menus,
		users: state.users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		menusActions: bindActionCreators({...menusActions}, dispatch),
		reviewsActions: bindActionCreators({...reviewsActions}, dispatch),
		supportActions: bindActionCreators({...supportActions}, dispatch),
		contactActions: bindActionCreators({...contactActions}, dispatch),
		usersActions: bindActionCreators({...usersActions}, dispatch),
		adminsActions: bindActionCreators({...adminsActions}, dispatch),
		labellingActions: bindActionCreators({...labellingActions}, dispatch),
		migrationActions: bindActionCreators({...migrationActions}, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
