import {
    GET_CHAINS_SUCCESS,
    GET_CHAINS_ERROR,
    APPROVE_ALL_RESTAURANTS_SUCCESS,
    APPROVE_ALL_RESTAURANTS_ERROR,
    DISAPPROVE_ALL_RESTAURANTS_SUCCESS,
    DISAPPROVE_ALL_RESTAURANTS_ERROR,
} from '../actions/chainsActions';

const initialState = {
    chains: [],
    error: null,
};

export default function chainsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CHAINS_SUCCESS:
            return { ...state, chains: action.chains, error: null };
        case GET_CHAINS_ERROR:
            return { ...state, error: action.error };
        case APPROVE_ALL_RESTAURANTS_SUCCESS:
            return { ...state, error: null };
        case APPROVE_ALL_RESTAURANTS_ERROR:
            return { ...state, error: action.error };
        case DISAPPROVE_ALL_RESTAURANTS_SUCCESS:
            return { ...state, error: null };
        case DISAPPROVE_ALL_RESTAURANTS_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
}