import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import Businesses from './containers/Businesses';
import Chains from './containers/chains';
import Intro from './containers/Intro';
import MigrationScript from './containers/MigrationScript';
import MigrationScriptReverse from './containers/MigrationScriptReverse';
import ProductLabelling from './containers/ProductLabelling';
import Restaurants from './containers/Restaurants';
import Reviews from './containers/Reviews';
import SuggestedEntities from './containers/SuggestedEntities';
import WebsiteMigration from './containers/WebsiteMigration';
import './style.scss';

import Navbar from './components/navbar/Navbar';
import Admins from './containers/Admins';
import Contact from './containers/Contact';
import Logout from './containers/Logout';
import Support from './containers/Support';
import UserGraphs from './containers/UserGraphs';
import Users from './containers/Users';

import { LOGIN_RESTORE_HASH } from './actions/loginActions';
import CacheManagement from './containers/CacheManagement';
import { Api, TEST_ENV } from './resources/Api';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const testEnvPath = '/';
const prodEnvPath = '/';

const isProductionBuild = process.env.NODE_ENV === 'production';
const baseName = isProductionBuild ? (TEST_ENV ? testEnvPath : prodEnvPath) : '';

const adminHash = localStorage.getItem('vf-admin-hash');
const adminHashTest = localStorage.getItem('vf-admin-hash-test');
const adminUsername = localStorage.getItem('vf-admin-username');
const isSuperAdmin = localStorage.getItem('vf-is-super-admin');

if (adminHash) {
	Api._credentials.hash = adminHash;
	store.dispatch({
		type: LOGIN_RESTORE_HASH,
		hash: adminHash,
		test_hash: adminHashTest,
		username: adminUsername,
		superAdmin: !!isSuperAdmin,
	});
}

function App() {
	return (
		<Provider store={ store }>
			<Router basename={ baseName }>
				<div className="App he">
					<Navbar />
					<div className="container">
						<Routes>
							<Route path={ '/' } element={ <Intro /> } />
							<Route path={ '/reviews' } element={ <Reviews /> } />
							<Route path={ '/restaurants' } element={ <Restaurants /> } />
							<Route path={ '/businesses' } element={ <Businesses /> } />
							<Route path={ '/contact' } element={ <Contact /> } />
							<Route path={ '/contact/:id' } element={ <Contact /> } />
							<Route path={ '/migrationScript' } element={ <MigrationScript /> } />
							<Route path={ '/migrationScriptReverse' } element={ <MigrationScriptReverse /> } />
							<Route path={ '/productLabelling' } element={ <ProductLabelling /> } />
							<Route path={ '/logout' } element={ <Logout /> } />
							<Route path={ '/users' } element={ <Users /> } />
							<Route path={ '/cache-management' } element={ <CacheManagement /> } />
							<Route path={ '/suggested-entities' } element={ <SuggestedEntities /> } />
							<Route path="/chains" element={<Chains />} />
						</Routes>
					</div>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
